import { CommonState, Fetcher } from '@primeit/components-vue';

// const mock = {
//   settings: {}
// };

const module = {
  actions: {
    FETCH_DELETE_TERM: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_USER}/term/${params.type}/${params.term.id}`,
          {
            body: JSON.stringify(params.term),
            headers: {
              Accept: 'application/json, text/plain, */*',
              'Content-Type': 'application/json',
            },
            method: 'DELETE',
          }
        );
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },
    FETCH_GET_TERM_ADMIN: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_USER}/term/admin`);
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_TERM_ADMIN', json);
    },
    FETCH_GET_TERM_PERSON: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_USER}/term/patient`);
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_TERM_PERSON', json);
    },
    FETCH_GET_TERM_PROFESSIONAL: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_USER}/term/professional`);
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_TERM_PROFESSIONAL', json);
    },

    FETCH_SAVE_TERM: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_USER}/term/${params.type}`, {
          body: JSON.stringify(params.term),
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
          method: 'POST',
        });
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },

    FETCH_SAVE_TERM_ADMIN: async (context, term) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_USER}/term/admin`, {
          body: JSON.stringify(term),
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
          method: 'POST',
        });
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },

    FETCH_SAVE_TERM_PERSON: async (context, term) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_USER}/term/patient`, {
          body: JSON.stringify(term),
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
          method: 'POST',
        });
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },

    FETCH_SAVE_TERM_PROFESSIONAL: async (context, term) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_USER}/term/professional`, {
          body: JSON.stringify(term),
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
          method: 'POST',
        });
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },

    FETCH_UPDATE_TERM: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_USER}/term/${params.type}/${params.term.id}`,
          {
            body: JSON.stringify(params.term),
            headers: {
              Accept: 'application/json, text/plain, */*',
              'Content-Type': 'application/json',
            },
            method: 'PUT',
          }
        );
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },
  },
  getters: {
    GET_TERM_ADMIN: (state) => {
      return JSON.parse(JSON.stringify(state.termAdmin));
    },
    GET_TERM_PERSON: (state) => {
      return JSON.parse(JSON.stringify(state.termPerson));
    },
    GET_TERM_PROFESSIONAL: (state) => {
      return JSON.parse(JSON.stringify(state.termProfessional));
    },
  },
  mutations: {
    POPULATE_TERM_ADMIN: (state, payload) => {
      if (payload) {
        state.termAdmin = payload;
      }
    },
    POPULATE_TERM_PERSON: (state, payload) => {
      if (payload) {
        state.termPerson = payload;
      }
    },
    POPULATE_TERM_PROFESSIONAL: (state, payload) => {
      if (payload) {
        state.termProfessional = payload;
      }
    },
    POPULATE_TERMS: (state, payload) => {
      if (payload) {
        switch (payload.type) {
          case 'admin':
            state.termAdmin = payload.terms;
            break;
          case 'person':
            state.termPerson = payload.terms;
            break;
          case 'professional':
            state.termProfessional = payload.terms;
            break;
        }
      }
    },
  },
  namespaced: true,
  state: {
    termAdmin: null,
    termPerson: null,
    termProfessional: null,
  },
};

CommonState.registerModule('term', module);
