import { CommonRouter } from '@primeit/components-vue';

CommonRouter.registerRoutes([
  {
    children: [
      {
        component: () => import(/* webpackChunkName: "Common" */ './Developer.vue'),
        meta: {
          label: 'settings.developer',
        },
        path: '/developer',
      },
      {
        component: () => import(/* webpackChunkName: "Common" */ './General.vue'),
        meta: {
          label: 'settings.general',
        },
        path: '/general',
      },
      {
        component: () => import(/* webpackChunkName: "Common" */ './Company.vue'),
        meta: {
          label: 'settings.company',
        },
        path: '/company',
      },
      {
        component: () => import(/* webpackChunkName: "Common" */ './plan/List.vue'),
        meta: {
          label: 'plan.list',
        },
        path: '/plan',
      },
      {
        component: () => import(/* webpackChunkName: "Common" */ './plan/New.vue'),
        meta: {
          label: 'plan.new',
        },
        path: '/plan/new',
      },
      {
        component: () => import(/* webpackChunkName: "Common" */ './plan/Detail.vue'),
        meta: {
          label: 'plan.detail',
        },
        path: '/plan/detail/:id',
      },
      {
        component: () => import(/* webpackChunkName: "Common" */ './plan/Edit.vue'),
        meta: {
          label: 'plan.edit',
        },
        path: '/plan/edit/:id',
      },
      {
        component: () => import(/* webpackChunkName: "Common" */ './CustomMessages.vue'),
        meta: {
          label: 'company.custom_messages',
        },
        path: '/messages',
      },
      {
        component: () => import(/* webpackChunkName: "Common" */ './Term.vue'),
        meta: {
          label: 'company.term',
        },
        path: '/term',
      },
    ],
    component: () => import(/* webpackChunkName: "Common" */ '../common/Common.vue'),
    meta: {
      icon: 'settings_applications',
      label: 'settings.edit',
      name: 'settings',
      order: 11,
      subroutes: [
        {
          label: 'settings.developer',
          path: '/developer',
          visible: true,
          visibleRoles: ['C', 'DC'],
        },
        {
          label: 'settings.general',
          path: '/general',
          visible: true,
          visibleRoles: ['C', 'DC'],
        },
        {
          label: 'settings.company',
          path: '/company',
          visible: true,
          visibleRoles: ['C', 'DC'],
        },
        {
          label: 'plan.list',
          path: '/plan',
          visible: true,
          visibleRoles: ['C', 'DC', 'M'],
        },
        {
          label: 'settings.custom_messages',
          path: '/messages',
          visible: true,
          visibleRoles: ['C', 'DC'],
        },
        {
          label: 'settings.term',
          path: '/term',
          visible: true,
          visibleRoles: ['C', 'DC'],
        },
        {
          label: 'company.screening',
          path: '/screening',
          visible: true,
          visibleRoles: ['C', 'DC'],
        },
      ],
      visible: true,
      visibleRoles: ['C', 'DC', 'M'],
    },
    path: '/general',
  },
]);
