const managerRoutes = [
  {
    children: [
      {
        children: [],
        component: () => import(/* webpackChunkName: "emergency.manager" */ './Dashboard.vue'),
        meta: {
          label: 'emergency.attendance_now',
        },
        path: '',
      },
    ],
    component: () => import(/* webpackChunkName: "emergency.manager" */ './Common.vue'),
    meta: {
      label: 'emergency.virtual_line',
    },
    path: 'manager',
  },
];

export default managerRoutes;
