import { CommonRouter } from '@primeit/components-vue';

CommonRouter.registerRoutes([
  {
    children: [
      {
        component: () => import(/* webpackChunkName: "Common" */ './List.vue'),
        meta: {
          label: 'patient.list',
        },
        path: '',
      },
      {
        component: () => import(/* webpackChunkName: "Common" */ './New.vue'),
        meta: {
          label: 'patient.new',
        },
        path: 'new',
      },
      {
        component: () => import(/* webpackChunkName: "Common" */ './Detail.vue'),
        meta: {
          label: 'patient.detail',
        },
        path: 'detail/:id',
      },
      {
        component: () => import(/* webpackChunkName: "Common" */ './Edit.vue'),
        meta: {
          label: 'patient.edit',
        },
        path: 'edit/:id',
      },
    ],
    component: () => import(/* webpackChunkName: "Common" */ '../common/Common.vue'),
    meta: {
      icon: 'person',
      label: 'patient.list',
      order: 4,
      visible: true,
    },
    path: '/patient',
  },
]);
