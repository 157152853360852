import { CommonRouter } from '@primeit/components-vue';

CommonRouter.registerRoutes([
  {
    children: [
      {
        component: () => import(/* webpackChunkName: "Common" */ './List.vue'),
        meta: {
          label: 'message.list',
        },
        path: '',
      },
      {
        component: () => import(/* webpackChunkName: "Common" */ './New.vue'),
        meta: {
          label: 'message.new',
        },
        path: 'new',
      },
      {
        component: () => import(/* webpackChunkName: "Common" */ './Detail.vue'),
        meta: {
          label: 'message.detail',
        },
        path: 'detail/:id',
      },
    ],
    component: () => import(/* webpackChunkName: "Common" */ '../common/Common.vue'),
    meta: {
      icon: 'mail',
      label: 'message.list',
      order: 11,
      visible: false,
    },
    path: '/message',
  },
]);
