<template>
  <div>
    <v-autocomplete
      v-model="entity"
      :items="domainProfessional"
      item-value="id"
      item-text="name"
      clearable
      :label="$vuetify.lang.t('$vuetify.label.doctor_id')"
      :title="$vuetify.lang.t('$vuetify.label.doctor_id')"
      :hint="$vuetify.lang.t('$vuetify.label.doctor_id')"
      filled
      :return-object="returnObject"
      @input="updateValue"
    />
  </div>
</template>
<script>
export default {
  tag: 'professional-select',
  name: 'ProfessionalSelect',
  props: {
    value: [String, Object],
    returnObject: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    dialog: false,
    search: '',
    entity: null,
  }),
  computed: {
    domainProfessional: function () {
      const result = [];
      const doctor = JSON.parse(JSON.stringify(this.$store.getters['doctor/GET_ALL']));
      if (doctor) {
        doctor.find((value) => {
          if (value.crm) {
            result.push({
              id: value.id,
              name:
                value.name +
                ' | ' +
                value.crm +
                `${
                  value.medical_specialty && value.medical_specialty.length > 0
                    ? ' | ' + value.medical_specialty.toString()
                    : ''
                }`,
              medical_specialty: value.medical_specialty,
              mail: value.mail,
            });
          }
        });
      }
      return result;
    },
  },
  watch: {},
  async created() {
    if (!this.domainProfessional || this.domainProfessional.length < 1) {
      await this.$store.dispatch('doctor/FETCH_ALL', { fields: 'id,name,crm,medical_specialty' });
    }
    this.entity = this.value;
  },
  methods: {
    updateValue: function (val) {
      this.$emit('input', val);
    },
    changeValue: function (val) {
      this.$emit('change', val);
    },
  },
};
</script>
