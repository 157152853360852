import { CommonRouter } from '@primeit/components-vue';

CommonRouter.registerRoutes([
  {
    children: [
      {
        component: () => import(/* webpackChunkName: "Common" */ './List.vue'),
        meta: {
          label: 'doctor.list',
        },
        path: '',
      },
      {
        component: () => import(/* webpackChunkName: "Common" */ './New.vue'),
        meta: {
          label: 'doctor.new',
        },
        path: 'new',
      },
      {
        component: () => import(/* webpackChunkName: "Common" */ './Detail.vue'),
        meta: {
          label: 'doctor.detail',
        },
        path: 'detail/:id',
      },
      {
        component: () => import(/* webpackChunkName: "Common" */ './Edit.vue'),
        meta: {
          label: 'doctor.edit',
        },
        path: 'edit/:id',
      },
    ],
    component: () => import(/* webpackChunkName: "Common" */ '../common/Common.vue'),
    meta: {
      icon: 'assignment_ind',
      label: 'doctor.list',
      order: 5,
      visible: true,
    },
    path: '/doctor',
  },
]);
