import './assets/scss/main.scss';

import Vue from 'vue';

///
import './_integration/sentry.io';
import vuetify from './_integration/vuetify';

///
import VueRouter from 'vue-router';
import VueClipboards from 'vue-clipboards';
import VueTheMask from 'vue-the-mask';
import VueExcelExport from 'vue-excel-export';
import 'intersection-observer'; /// Safari 6 ou maior

import App from './App.vue';

import PersonSelect from './components/Selects/PersonSelect.vue';
import ProfessionalSelect from './components/Selects/ProfessionalSelect.vue';

// Componente
import PrimeIT, { CommonState, CommonRouter } from '@primeit/components-vue';

// Pages
import '@/pages';

// States
import '@/state';

Vue.component('person-select', PersonSelect);
Vue.component('professional-select', ProfessionalSelect);

// Vue Setup
Vue.use(PrimeIT);
Vue.use(VueRouter);
Vue.use(VueClipboards);
Vue.use(VueTheMask);
Vue.use(VueExcelExport);

/* eslint-disable no-new */
new Vue({
  render: (h) => h(App),
  router: CommonRouter,
  store: CommonState,
  vuetify,
}).$mount('#app');
