import { CommonState, Fetcher } from '@primeit/components-vue';

// const mock = {
//   finance: {}
// };

const module = {
  actions: {
    FETCH_COMPANY_FINANCE: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_COMPANY}/${params.companyId}/financial`
        );
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_COMPANY_FINANCE', json);
    },
    FETCH_COMPANY_FINANCERESUME: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_COMPANY}/${params.companyId}/financialresume`
        );
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_COMPANY_FINANCERESUME', json);
    },
    FETCH_SAVE: async (context, financial) => {
      let response;
      let json;
      const companyId = financial.companyId;
      delete financial.companyId;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_COMPANY}/${companyId}/financial`, {
          body: JSON.stringify(financial),
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
          method: 'PUT',
        });
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },
  },
  getters: {
    GET_COMPANY_FINANCE: (state) => state.entityFinance,
    GET_COMPANY_FINANCERESUME: (state) => state.entityFinanceResume,
  },
  mutations: {
    POPULATE_COMPANY_FINANCE: (state, payload) => {
      if (payload) {
        state.entityFinance = payload;
      }
    },
    POPULATE_COMPANY_FINANCERESUME: (state, payload) => {
      if (payload) {
        state.entityFinanceResume = payload;
      }
    },
  },
  namespaced: true,
  state: {
    entityFinance: null,
    entityFinanceResume: null,
  },
};

CommonState.registerModule('financial', module);
