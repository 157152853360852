import { CommonRouter } from '@primeit/components-vue';

CommonRouter.registerRoutes([
  {
    children: [
      {
        component: () => import(/* webpackChunkName: "Common" */ './List.vue'),
        meta: {
          label: 'appointment.list',
          secure: true,
        },
        path: '',
      },
      {
        component: () => import(/* webpackChunkName: "Common" */ './New.vue'),
        meta: {
          label: 'appointment.new',
          secure: true,
        },
        path: 'new',
      },
      {
        component: () => import(/* webpackChunkName: "Common" */ './Detail.vue'),
        meta: {
          label: 'appointment.detail',
          secure: true,
        },
        path: 'detail/:id',
      },
      {
        component: () => import(/* webpackChunkName: "Common" */ './Edit.vue'),
        meta: {
          label: 'appointment.edit',
          secure: true,
        },
        path: 'edit/:id',
      },
      {
        component: () => import(/* webpackChunkName: "Common" */ './CalendarSchedule.vue'),
        meta: {
          label: 'appointment.calendar',
          secure: true,
        },
        path: '/calendar_schedule',
      },
      {
        component: () => import(/* webpackChunkName: "Common" */ './New.vue'),
        meta: {
          label: 'appointment.new',
          secure: true,
        },
        path: '/new_appointment',
      },
    ],
    component: () => import(/* webpackChunkName: "Common" */ '../common/Common.vue'),
    meta: {
      icon: 'today',
      label: 'appointment.list',
      order: 1,
      subroutes: [
        {
          icon: 'mdi-plus',
          label: 'appointment.new',
          path: '/new_appointment',
          visible: true,
        },
        {
          label: 'appointment.list_appointments',
          path: '/appointment',
          visible: true,
        },
        {
          label: 'appointment.calendar',
          path: '/calendar_schedule',
          visible: true,
        },
      ],
      visible: true,
    },
    path: '/appointment',
  },
]);

// {
//   component: () => import('./NewAppointment.vue'),
//   meta: {
//     label: 'appointment.new',
//     secure: true
//   },
//   path: '/new-appointment'
// },