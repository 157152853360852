import { CommonRouter } from '@primeit/components-vue';

CommonRouter.registerRoutes([
  {
    children: [
      {
        component: () => import(/* webpackChunkName: "Common" */ './List.vue'),
        meta: {
          label: 'user.list',
        },
        path: '',
      },
      {
        component: () => import(/* webpackChunkName: "Common" */ './New.vue'),
        meta: {
          label: 'user.new',
        },
        path: 'new',
      },
      {
        component: () => import(/* webpackChunkName: "Common" */ './Detail.vue'),
        meta: {
          label: 'user.detail',
        },
        path: 'detail/:id',
      },
      {
        component: () => import(/* webpackChunkName: "Common" */ './Edit.vue'),
        meta: {
          label: 'user.edit',
        },
        path: 'edit/:id',
      },
    ],
    component: () => import(/* webpackChunkName: "Common" */ '../common/Common.vue'),

    meta: {
      icon: 'security',
      label: 'user.list',
      order: 7,
      visible: true,
      visibleRoles: ['DC', 'C'],
    },
    path: '/user',
  },
]);
