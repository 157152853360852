import { CommonRouter } from '@primeit/components-vue';

CommonRouter.registerRoutes([
  {
    children: [
      {
        component: () => import(/* webpackChunkName: "Common" */ './Edit.vue'),
        meta: {
          label: 'finance.edit',
        },
        path: '',
      },
      {
        component: () => import(/* webpackChunkName: "Common" */ '../voucher/List.vue'),
        meta: {
          label: 'voucher.list',
        },
        path: '',
      },
    ],
    component: () => import(/* webpackChunkName: "Common" */ '../common/Common.vue'),

    meta: {
      icon: 'attach_money',
      label: 'finance.financial',
      order: 9,
      subroutes: [
        {
          label: 'voucher.list',
          path: '/voucher',
          visible: true,
        },
        {
          label: 'finance.edit',
          path: '/financial',
          visible: true,
        },
      ],
      visible: true,
      visibleRoles: ['C', 'DC'],
    },
    path: '/financial',
  },
]);
