import { CommonState, Fetcher, FetchHelper } from '@primeit/components-vue';
const fetchHelper = new FetchHelper();
const module = {
  actions: {
    FETCH_ALL: async (context, params) => {
      const responseJson = await fetchHelper.fetchAll(process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PERSON, params, true);
      context.commit('POPULATE_ALL', responseJson);
    },
    FETCH_ALL_PARENT: async (context, params) => {
      const responseJson = await fetchHelper.fetchAll(process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PERSON, params, true);
      context.commit('POPULATE_PARENT', responseJson);
    },
    FETCH_ALTER_STATUS: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PERSON}/${params.id}/status`, {
          headers: {
            'x-company-id': params.company_id,
          },
          method: 'PUT',
        });
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },
    FETCH_CREATE_EVENT: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PERSON}/${params.id}/diagnostic`, {
          body: JSON.stringify(params.body),
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
          method: 'POST',
        });
        json = {
          ...(await response.json()),
          isDiagnostic: true,
        };
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },
    FETCH_DELETE: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PERSON}/${params}`, {
          method: 'DELETE',
        });
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },
    FETCH_DELETE_ALL: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PERSON}?id=${params}`, {
          method: 'DELETE',
        });
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },
    FETCH_DELETE_EVENT: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PERSON}/${params.id}/diagnostic/${params.historyId}`,
          {
            method: 'DELETE',
          }
        );
        json = {
          ...(await response.json()),
          isDiagnostic: true,
        };
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },
    FETCH_EDIT: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PERSON}/${params}`);
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_EDIT', json);
    },
    FETCH_HISTORY: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PERSON}/${params}/history`);
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }

      if (json) {
        context.commit('POPULATE_HISTORY', json);
      }
    },
    FETCH_HISTORY_ONE_APP: async (context, params) => {
      let response;
      let json;

      const id = params.person_id;
      const queryParams = `?item_type=${params.type}&item_id=${params.id}`;

      try {
        response = await Fetcher(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PERSON}/${id}/history/item${queryParams}`
        );
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }

      if (json) {
        context.commit('POPULATE_HISTORY_IN_ONE_APP', json);
      }
    },
    FETCH_NEW: async (context, params) => {
      let response;
      let json;
      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PERSON}/new`);
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_NEW', json);
    },
    FETCH_NOTIFICATION_CHANNELS: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PERSON}/${params}/notificationchannels`);
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_NOTIFICATION_CHANNELS', json);
    },
    FETCH_NOTIFY: async (context, params) => {
      let response;
      let json;

      const body = {
        notification_channels: params.notification_channels,
      };

      try {
        response = await Fetcher(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PERSON}/${params.entity_id}/diagnostic/${params.history_id}/notify`,
          {
            body: JSON.stringify(body),
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            method: 'POST',
          }
        );
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }

      context.commit('POPULATE_NOTIFY', json);
    },
    FETCH_ONE: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PERSON}/${params}`);
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_ONE', json);
    },
    FETCH_PERSON_PARENT: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PERSON}/${params}`);
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_PERSON_PARENT', json);
    },
    FETCH_SAVE: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PERSON}`, {
          body: JSON.stringify(params),
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
          method: 'POST',
        });
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },

    FETCH_UPDATE: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PERSON}/${params.id}`, {
          body: JSON.stringify(params),
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
          method: 'PUT',
        });
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },
    FETCH_VALIDATE: async (context, params) => {
      let response;
      let json;
      try {
        response = await Fetcher(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PERSON}/validate?field=${params.field}&condition=${params.condition}&value=${params.value}`
        );
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_VALIDATE', json);
    },
  },
  getters: {
    GET_ALL: (state) => {
      const list = JSON.parse(JSON.stringify(state.entities));

      list.map((patient) => {
        const cpf = patient.cpf
          ? patient.cpf.slice(0, 3) +
            '.' +
            patient.cpf.slice(3, 6) +
            '.' +
            patient.cpf.slice(6, 9) +
            '-' +
            patient.cpf.slice(9, 11)
          : null;
        const nameComplement = patient.registration || cpf;
        const nameComplementLookup = patient.registration || patient.cpf;
        const name = patient.name;
        patient.name_formatted = nameComplement ? name + ' | ' + nameComplement : name;
        patient.name_lookup = nameComplementLookup ? name + ' | ' + nameComplementLookup : name;

        return patient;
      });

      return list;
    },
    GET_ALL_PARENT: (state) => {
      const list = JSON.parse(JSON.stringify(state.entitiesParent));

      list.map((patient) => {
        const cpf = patient.cpf
          ? patient.cpf.slice(0, 3) +
            '.' +
            patient.cpf.slice(3, 6) +
            '.' +
            patient.cpf.slice(6, 9) +
            '-' +
            patient.cpf.slice(9, 11)
          : null;
        const nameComplement = patient.registration || cpf;
        const name = patient.name;
        patient.name_formatted = nameComplement ? name + ' | ' + nameComplement : name;
        return patient;
      });

      return list;
    },
    GET_EDIT: (state) => {
      return JSON.parse(JSON.stringify(state.edit));
    },
    GET_HISTORY: (state) => state.patient_history,
    GET_NEW: (state) => state.new,
    GET_NOTIFICATION_CHANNELS: (state) => state.notification_channels,
    GET_NOTIFY: (state) => state.notify,
    GET_ONE: (state) => {
      return JSON.parse(JSON.stringify(state.entity));
    },
    GET_PERSON_PARENT: (state) => state.personParent,
    GET_VALIDATE: (state) => state.validate,
  },
  mutations: {
    POPULATE_ALL: (state, payload) => {
      if (payload) {
        state.entities = payload;
      }
    },
    POPULATE_EDIT: (state, payload) => {
      state.edit = payload;
    },
    POPULATE_HISTORY: (state, payload) => {
      if (payload) {
        state.patient_history = payload;
      }
    },
    POPULATE_HISTORY_IN_ONE_APP: (state, payload) => {
      if (payload) {
        payload.alreadyGotInfo = true;
        const index = state.patient_history.findIndex((item) => item.id === payload.id);
        if (index !== -1) state.patient_history.splice(index, 1, payload);
      }
    },
    POPULATE_NEW: (state, payload) => {
      if (payload) {
        state.new = payload;
      }
    },
    POPULATE_NOTIFICATION_CHANNELS: (state, payload) => {
      if (payload) {
        state.notification_channels = payload;
      }
    },
    POPULATE_NOTIFY: (state, payload) => {
      if (payload) {
        state.notify = payload;
      }
    },
    POPULATE_ONE: (state, payload) => {
      if (payload) {
        state.entity = payload;
      }
    },
    POPULATE_PARENT: (state, payload) => {
      if (payload) {
        state.entitiesParent = payload;
      }
    },
    POPULATE_PERSON_PARENT: (state, payload) => {
      if (payload) {
        state.personParent = payload;
      }
    },
    POPULATE_VALIDATE: (state, payload) => {
      if (payload) {
        state.validate = payload;
      }
    },
  },
  namespaced: true,
  state: {
    edit: null,
    entities: [],
    entitiesParent: [],
    entity: null,
    new: [],
    notification_channels: [],
    notify: null,
    patient_history: undefined,
    personParent: undefined,
    validate: null,
  },
};

CommonState.registerModule('patient', module);
