import { CommonRouter } from '@primeit/components-vue';

CommonRouter.registerRoutes([
  {
    children: [
      {
        component: () => import(/* webpackChunkName: "Common" */ './Financial.vue'),
        meta: {
          label: 'finance.financial',
        },
        path: '/report/financial',
      },
      {
        component: () => import(/* webpackChunkName: "Common" */ './Rating.vue'),
        meta: {
          label: 'report.rating',
        },
        path: '/report/rating',
      },
      {
        component: () => import(/* webpackChunkName: "Common" */ './Term.vue'),
        meta: {
          label: 'report.term',
        },
        path: '/report/term',
      },
      {
        component: () => import(/* webpackChunkName: "Common" */ './Notifications.vue'),
        meta: {
          label: 'report.notifications',
        },
        path: '/report/notifications',
      },
    ],
    component: () => import(/* webpackChunkName: "Common" */ '../common/Common.vue'),
    meta: {
      icon: 'mdi-file-chart',
      label: 'report.title',
      order: 10,
      subroutes: [
        {
          label: 'finance.payments',
          path: '/report/financial',
          visible: true,
        },
        {
          label: 'report.rating',
          path: '/report/rating',
          visible: true,
        },
        {
          label: 'report.term',
          path: '/report/term',
          visible: true,
        },
        {
          label: 'report.notifications',
          path: '/report/notifications',
          visible: true,
        },
      ],
      visible: true,
    },
    path: '/report',
  },
]);
