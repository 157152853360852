import { CommonRouter } from '@primeit/components-vue';

CommonRouter.registerRoutes([
  {
    children: [
      {
        component: () => import(/* webpackChunkName: "Common" */ './List.vue'),
        meta: {
          label: 'voucher.list',
        },
        path: '',
      },
      {
        component: () => import(/* webpackChunkName: "Common" */ './New.vue'),
        meta: {
          label: 'voucher.new',
        },
        path: 'new',
      },
      {
        component: () => import(/* webpackChunkName: "Common" */ './Detail.vue'),
        meta: {
          label: 'voucher.detail',
        },
        path: 'detail/:id',
      },
      // {
      //   component: () => import(/* webpackChunkName: "Common" */ './Edit.vue'),
      //   meta: {
      //     label: 'voucher.edit',
      //   },
      //   path: 'edit/:id',
      // },
    ],
    component: () => import(/* webpackChunkName: "Common" */ '../common/Common.vue'),
    meta: {
      icon: 'fact_check',
      label: 'voucher.list',
      order: 350,
      visible: false,
    },
    path: '/voucher',
  },
]);
