import { CommonState, FetchHelper } from '@primeit/components-vue';
const fetcherHelper = new FetchHelper();
const module = {
  actions: {
    FETCH_NOTIFICATIONS: async (context, params) => {
      const response = await fetcherHelper.fetchAll(
        `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_NOTIFICATIONS}/report`,
        // 'https://localhost:9246/notifications/report',
        params,
        true
      );
      context.commit('POPULATE_NOTIFICATIONS', response);
    },
  },
  getters: {
    GET_NOTIFICATIONS_REPORT: (state) => state.notifications_report,
  },
  mutations: {
    POPULATE_NOTIFICATIONS: (state, payload) => {
      if (payload) {
        state.notifications_report = payload;
      }
    },
  },
  namespaced: true,
  state: {
    notifications_report: null,
  },
};

CommonState.registerModule('notifications', module);
