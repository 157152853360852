import { CommonState, Fetcher, FetchHelper } from '@primeit/components-vue';
const fetchHelper = new FetchHelper();
const module = {
  actions: {
    FETCH_ALL: async (context, params) => {
      const responseJson = await fetchHelper.fetchAll(process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PROFESSIONAL, params);
      context.commit('POPULATE_ALL', responseJson);
    },
    FETCH_ALTER_STATUS: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PROFESSIONAL}/${params.id}/status`, {
          headers: {
            'x-company-id': params.company_id,
          },
          method: 'PUT',
        });
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },
    FETCH_AVAILABILITY_CALENDAR: async (context, params) => {
      const responseJson = await fetchHelper.fetchAll(
        `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PROFESSIONAL}/availabilitycalendar`,
        params
      );
      context.commit('POPULATE_SCHEDULES', responseJson);
    },
    FETCH_DELETE: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PROFESSIONAL}/${params}`, {
          method: 'DELETE',
        });
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },
    FETCH_DELETE_ALL: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PROFESSIONAL}?id=${params}`, {
          method: 'DELETE',
        });
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },
    FETCH_DOCTOR_MANAGER: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PROFESSIONAL}/${params}`);
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_DOCTOR_MANAGER_LOGGED', json);
    },
    FETCH_EDIT: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PROFESSIONAL}/${params}`);
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_EDIT', json);
    },
    FETCH_NEW: async (context, params) => {
      let response;
      let json;
      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PROFESSIONAL}/new`);
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_NEW', json);
    },
    FETCH_NOTIFICATION_CHANNELS: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PROFESSIONAL}/${params}/notificationchannels`
        );
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_NOTIFICATION_CHANNELS', json);
    },
    FETCH_ONE: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PROFESSIONAL}/${params}`);
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_ONE', json);
    },
    FETCH_SAVE: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PROFESSIONAL}`, {
          body: JSON.stringify(params),
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
          method: 'POST',
        });
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },
    FETCH_UPDATE: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PROFESSIONAL}/${params.id}`, {
          body: JSON.stringify(params),
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
          method: 'PUT',
        });
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },
    FETCH_VALIDATE: async (context, params) => {
      let response;
      let json;
      try {
        response = await Fetcher(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PROFESSIONAL}/validate?field=${params.field}&condition=${params.condition}&value=${params.value}`
        );
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_VALIDATE', json);
    },
  },
  getters: {
    GET_ALL: (state) => state.entities,
    GET_DOCTOR_MANAGER_LOGGED: (state) => state.doctor_manager_logged,
    GET_EDIT: (state) => {
      return JSON.parse(JSON.stringify(state.edit));
    },
    GET_NEW: (state) => state.new,
    GET_NOTIFICATION_CHANNELS: (state) => state.notification_channels,
    GET_ONE: (state) => {
      return JSON.parse(JSON.stringify(state.entity));
    },
    GET_SCHEDULES: (state) => {
      return JSON.parse(JSON.stringify(state.schedules));
    },
    GET_VALIDATE: (state) => state.validate,
  },
  mutations: {
    POPULATE_ALL: (state, payload) => {
      if (payload) {
        state.entities = payload;
      }
    },
    POPULATE_DOCTOR_MANAGER_LOGGED: (state, payload) => {
      if (payload) {
        state.doctor_manager_logged = payload;
      }
    },
    POPULATE_EDIT: (state, payload) => {
      if (payload) {
        state.edit = payload;
      }
    },
    POPULATE_NEW: (state, payload) => {
      if (payload) {
        state.new = payload;
      }
    },
    POPULATE_NOTIFICATION_CHANNELS: (state, payload) => {
      if (payload) {
        state.notification_channels = payload;
      }
    },
    POPULATE_ONE: (state, payload) => {
      if (payload) {
        state.entity = payload;
      }
    },
    POPULATE_SCHEDULES: (state, payload) => {
      if (payload) {
        state.schedules = payload;
      }
    },
    POPULATE_VALIDATE: (state, payload) => {
      if (payload) {
        state.validate = payload;
      }
    },
  },
  namespaced: true,
  state: {
    doctor_manager_logged: null,
    edit: null,
    entities: [],
    entity: null,
    new: [],
    notification_channels: [],
    schedules: null,
    validate: null,
  },
};

CommonState.registerModule('doctor', module);
