import { CommonState, API } from '@primeit/components-vue';

const module = {
  actions: {
    FETCH_FORGOT: async (context, params) => {
      // console.log('valor de params ', params);

      const response = await fetch('/', { method: 'HEAD' });
      const companyId = response.headers.get('x-domain-dav-company-id');

      const apiName = 'UserAPI';
      const path = '/forgot';
      const myInit = {
        queryStringParameters: {
          companyId: companyId,
          email: params,
        },
        response: true,
      };

      API.get(apiName, path, myInit)
        .then((payload) => {
          context.commit('POPULATE_FORGOT', { code: payload.status, ts: Date.now() });
        })
        .catch((error) => {
          // console.log(error);
          return error;
        });
    },
  },
  getters: {
    GET_ALL: (state) => state.entities,
    GET_EDIT: (state) => {
      return JSON.parse(JSON.stringify(state.edit));
    },
    GET_FORGOT: (state) => state.user,
    GET_NEW: (state) => state.new,
    GET_ONE: (state) => {
      return JSON.parse(JSON.stringify(state.entity));
    },
    GET_VALIDATE: (state) => state.validate,
    TYPE: (state) => state.typeOptions,
  },
  mutations: {
    POPULATE_ALL: (state, payload) => {
      if (payload) {
        state.entities = payload;
      }
    },
    POPULATE_EDIT: (state, payload) => {
      if (payload) {
        state.edit = payload;
      }
    },
    POPULATE_FORGOT: (state, payload) => {
      if (payload) {
        state.user = payload;
      }
    },
    POPULATE_NEW: (state, payload) => {
      if (payload) {
        state.new = payload;
      }
    },

    POPULATE_ONE: (state, payload) => {
      if (payload) {
        state.entity = payload;
      }
    },
    POPULATE_TYPE: (state, payload) => {
      if (payload) {
        state.typeOptions = payload;
      }
    },
    POPULATE_VALIDATE: (state, payload) => {
      if (payload) {
        state.entities = payload;
      }
    },
  },
  namespaced: true,

  state: {
    edit: null,
    entities: [],
    entity: null,
    new: [],
    typeOptions: [],
    user: [],
    validate: null,
  },
};

CommonState.registerModule('login', module);
