import { CommonRouter } from '@primeit/components-vue';

import manager from './manager';

CommonRouter.registerRoutes([
  {
    children: [
      ...manager,
      {
        component: () => import(/* webpackChunkName: "emergency.manager" */ './Report.vue'),
        meta: {
          label: 'report.protocols',
        },
        path: 'report',
      },
      {
        component: () => import(/* webpackChunkName: "emergency.manager" */ './Group.vue'),
        meta: {
          label: 'emergency.group',
        },
        path: 'group',
      },
    ],
    component: () => import(/* webpackChunkName: "emergency.common" */ '../common/Common.vue'),
    meta: {
      icon: 'local_hospital',
      label: 'emergency.virtual_line',
      order: 3,
      subroutes: [
        {
          label: 'emergency.dashboard',
          path: '/emergency/manager',
          visible: true,
        },
        {
          label: 'report.protocols',
          path: '/emergency/report',
          visible: true,
        },
        {
          label: 'emergency.group',
          path: '/emergency/group',
          visible: true,
        },
      ],
      visible: true,
      visibleRoles: ['M', 'C', 'DC'],
    },
    path: '/emergency',
  },
]);
