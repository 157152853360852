import { CommonRouter } from '@primeit/components-vue';

CommonRouter.registerRoutes([
  {
    children: [
      {
        component: () => import(/* webpackChunkName: "Common" */ './List.vue'),
        meta: {
          label: 'company.screening',
        },
        path: '/screening',
      },
      {
        component: () => import(/* webpackChunkName: "Common" */ './Edit.vue'),
        meta: {
          label: 'company.screening',
        },
        path: '/screening/edit/:id',
      },
      {
        component: () => import(/* webpackChunkName: "Common" */ './Detail.vue'),
        meta: {
          label: 'company.screening',
        },
        path: '/screening/detail/:id',
      },
      {
        component: () => import(/* webpackChunkName: "Common" */ './New.vue'),
        meta: {
          label: 'company.screening',
        },
        path: '/screening/new',
      },
    ],
    component: () => import(/* webpackChunkName: "Common" */ '../common/Common.vue'),
    meta: {
      icon: '',
      label: 'company.screening',
      order: 0,
      visible: false,
      visibleRoles: ['C', 'DC'],
    },
    path: '/screening',
  },
]);
