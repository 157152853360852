/**
 * Módulo comum de domínio do sistema
 * Author: Luciano Jesus Lima <lucianojl@primeit.com.br>
 */
import { CommonState, Fetcher } from '@primeit/components-vue';

const ordemAlfabeticaValue = (a, b) => {
  if (a.value.toLowerCase() > b.value.toLowerCase()) {
    return 1;
  }
  if (a.value.toLowerCase() < b.value.toLowerCase()) {
    return -1;
  }
  // a must be equal to b
  return 0;
};

const module = {
  actions: {
    /**
     * Busca endereço a partir do CEP
     *
     *  Exemplo de envio
     *  const params = { cep: 8888888 }
     *
     * @param context
     * @param params
     * @returns {Promise<void>}
     * @constructor
     */
    FETCH_ADDRESS: async (context, params) => {
      let json;
      try {
        const response = await fetch(`${process.env.VUE_APP_CEP_API}/${params.cep}/json`);
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          empty: {},
          error: {
            code: 500,
            message: ['Impossível acessar o servidor. Tente novamente'],
          },
        };
      }
      context.commit('POPULATE_ADDRESS', json);
    },

    FETCH_APPOINTMENT_SOURCE: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_DOMAIN}/APPOINTMENT_SOURCE`);
        if (response.ok) {
          json = await response.json();
          json = json.values;
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_APPOINTMENT_SOURCE', json);
    },

    FETCH_APPOINTMENT_STATUS_PAYMENT: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_DOMAIN}/APPOINTMENT_STATUS_PAYMENT`
        );
        if (response.ok) {
          json = await response.json();
          json = json.values;
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_APPOINTMENT_STATUS_PAYMENT', json);
    },

    FETCH_BANK_CODES: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_DOMAIN}/BANK_CODES`);
        if (response.ok) {
          json = await response.json();
          json = json.values;
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_BANK_CODES', json);
    },

    /**
     *
     * @param context
     * @param params
     * @returns {Promise<void>}
     * @constructor
     */
    FETCH_BILL_TYPE_OPTIONS: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_DOMAIN}/BILL_TYPE`);
        if (response.ok) {
          json = await response.json();
          json = json.values;
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_BILL_TYPE', json);
    },

    /**
     *
     * @param context
     * @param params
     * @returns {Promise<void>}
     * @constructor
     */
    FETCH_CALLBACK_EVENTS: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_DOMAIN}/CALLBACK_EVENTS_APPOINTMENT`
        );
        if (response.ok) {
          json = await response.json();
          json = json.values;
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_CALLBACK_EVENTS_APPOINTMENT', json);
    },

    /**
     *
     * @param context
     * @param params
     * @returns {Promise<void>}
     * @constructor
     */
    FETCH_CITIES: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_DOMAIN}/CITY?parent=${params.state}`
        );
        if (response.ok) {
          json = await response.json();
          const citys = [];
          json.values.sort(ordemAlfabeticaValue);
          for (const city of json.values) {
            citys.push({
              id: parseInt(city.code),
              nome: city.value,
            });
          }
          json = citys;
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_CITIES', json);
    },

    /**
     * @param context
     * @param params
     * @returns {Promise<void>}
     * @constructor
     */
    FETCH_COMMISSION_TYPE: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_DOMAIN}/COMMISSION_TYPE`);
        if (response.ok) {
          json = await response.json();
          json = json.values;
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_COMMISSION_TYPE', json);
    },

    /**
     *
     * @param context
     * @param params
     * @returns {Promise<void>}
     * @constructor
     */
    FETCH_COUNTRY: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_DOMAIN}/COUNTRY_LIST`);
        if (response.ok) {
          json = await response.json();
          json = json.values;
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_COUNTRY', json);
    },

    /**
     *
     * @param context
     * @param params
     * @returns {Promise<void>}
     * @constructor
     */
    /**
     *
     * @param context
     * @param params
     * @returns {Promise<void>}
     * @constructor
     */
    FETCH_CUSTOM_LANGUAGES: async (context, params) => {
      context.commit('POPULATE_CUSTOM_LANGUAGES', []);
      let json;
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_DOMAIN}/CUSTOM_LANGUAGES`);
        if (response.ok) {
          json = await response.json();
          json = json.values;
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_CUSTOM_LANGUAGES', json);
    },

    /**
     *
     * @param context
     * @param params
     * @returns {Promise<void>}
     * @constructor
     */
    FETCH_CUSTOM_MESSAGES: async (context, params) => {
      context.commit('POPULATE_CUSTOM_MESSAGES', []);
      let json;
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_DOMAIN}/CUSTOM_MESSAGES`);
        if (response.ok) {
          json = await response.json();
          json = json.values;
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_CUSTOM_MESSAGES', json);
    },

    /**
     *
     * @param context
     * @param params
     * @returns {Promise<void>}
     * @constructor
     */
    FETCH_DEFAULT_USER_PERMISSIONS: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_DOMAIN}/USER_PERMISSIONS`);
        if (response.ok) {
          json = await response.json();
          json = json.values;
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_DEFAULT_USER_PERMISSIONS', json);
    },

    /**
     *
     * @param context
     * @param params
     * @returns {Promise<void>}
     * @constructor
     */
    FETCH_FINANCIAL_TEXT_ACCOUNT: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_DOMAIN}/FINANCIAL_TEXT_ACCOUNT`);
        if (response.ok) {
          json = await response.json();
          json = json.values;
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_FINANCIAL_TEXT_ACCOUNT', json);
    },

    FETCH_FINANCIAL_TRANSFER_DAY: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_DOMAIN}/FINANCIAL_TRANSFER_DAY`);
        if (response.ok) {
          json = await response.json();
          json = json.values;
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_FINANCIAL_TRANSFER_DAY', json);
    },

    FETCH_FINANCIAL_TRANSFER_INTERVAL: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_DOMAIN}/FINANCIAL_TRANSFER_INTERVAL`
        );
        if (response.ok) {
          json = await response.json();
          json = json.values;
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_FINANCIAL_TRANSFER_INTERVAL', json);
    },

    FETCH_FINANCIAL_TYPE_ACCOUNT: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_DOMAIN}/FINANCIAL_TYPE_ACCOUNT`);
        if (response.ok) {
          json = await response.json();
          json = json.values;
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_FINANCIAL_TYPE_ACCOUNT', json);
    },

    /**
     *
     * @param context
     * @param params
     * @returns {Promise<void>}
     * @constructor
     */
    FETCH_GENDER_OPTIONS: async (context, params) => {
      context.commit('POPULATE_GENDER', []);
      let json;
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_DOMAIN}/GENDER`);
        if (response.ok) {
          json = await response.json();
          json = json.values;
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_GENDER', json);
    },

    FETCH_NOTIFICATION_CHANNEL: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_DOMAIN}/NOTIFICATION_CHANNEL`);
        if (response.ok) {
          json = await response.json();
          json = json.values;
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_NOTIFICATION_CHANNEL', json);
    },

    /**
     *
     * @param context
     * @param params
     * @returns {Promise<void>}
     * @constructor
     */
    FETCH_PARTICIPANT_ROLE: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_DOMAIN}/PARTICIPANT_ROLE`);
        if (response.ok) {
          json = await response.json();
          json = json.values;
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_PARTICIPANT_ROLE', json);
    },

    /**
     *
     * @param context
     * @param params
     * @returns {Promise<void>}
     * @constructor
     */
    FETCH_PAYMENT_INTERMEDIARY: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_DOMAIN}/PAYMENT_INTERMEDIARY`);
        if (response.ok) {
          json = await response.json();
          json = json.values;
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_PAYMENT_INTERMEDIARY', json);
    },

    /**
     *
     * @param context
     * @param params
     * @returns {Promise<void>}
     * @constructor
     */
    FETCH_PAYMENT_STATUS: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_DOMAIN}/PAYMENT_STATUS`);
        if (response.ok) {
          json = await response.json();
          json = json.values;
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_PAYMENT_STATUS', json);
    },

    FETCH_PAYMENT_TYPE: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_DOMAIN}/PAYMENT_TYPE`);
        if (response.ok) {
          json = await response.json();
          json = json.values;
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_PAYMENT_TYPE', json);
    },

    /**
     * Returns prescription address options
     * @param context
     * @param params
     * @returns {Promise<void>}
     */
    FETCH_PRESCRIPTION_ADDRESS_OPTIONS: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_DOMAIN}/PRESCRIPTION_ADDRESS_OPTIONS`
        );
        if (response.ok) {
          json = await response.json();
          json = json.values;
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_PRESCRIPTION_NAME_AND_ADDRESS_OPTIONS', json);
    },

    /**
     *
     * @param context
     * @param params
     * @returns {Promise<void>}
     * @constructor
     */
    FETCH_PROFESSIONAL_REGISTER_TYPE: async (context, params) => {
      context.commit('POPULATE_CUSTOM_LANGUAGES', []);
      let json;
      try {
        const response = await Fetcher(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_DOMAIN}/PROFESSIONAL_REGISTER_TYPE`
        );
        if (response.ok) {
          json = await response.json();
          json = json.values;
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_PROFESSIONAL_REGISTER_TYPE', json);
    },

    /**
     * Busca lista de cidades
     *
     *  Exemplo de envio
     *  const params = { state: <codigo ID do estado, não a sigla> }
     *
     * @param context
     * @param params
     * @returns {Promise<void>}
     * @constructor
     */
    FETCH_STATES: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_DOMAIN}/REGION`);
        if (response.ok) {
          json = await response.json();
          const regions = [];
          json.values.sort(ordemAlfabeticaValue);
          for (const region of json.values) {
            regions.push({
              id: region.code,
              sigla: region.code,
            });
          }

          json = regions;
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_STATES', json);
    },

    /**
     * @param context
     * @param params
     * @returns {Promise<void>}
     * @constructor
     */
    FETCH_STATUS: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_DOMAIN}/STATUS`);
        if (response.ok) {
          json = await response.json();
          json = json.values;
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_STATUS', json);
    },

    FETCH_STATUS_APPOINTMENT: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_DOMAIN}/STATUS_APPOINTMENT`);
        if (response.ok) {
          json = await response.json();
          json = json.values;
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_STATUS_APPOINTMENT', json);
    },

    /**
     *
     * @param context
     * @param params
     * @returns {Promise<void>}
     * @constructor
     */
    FETCH_THEME_ROOM_LIGHT: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_DOMAIN}/THEME_ROOM_LIGHT`);
        if (response.ok) {
          json = await response.json();
          json = json.values;
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_THEME_ROOM_LIGHT', json);
    },

    /**
     * Busca lista de Estados
     * OBS.: Guardar na base a sigla, não o id. O id serve para a chamada de cidades
     *
     * @param context
     * @param params
     * @returns {Promise<void>}
     * @constructor
     */
    FETCH_TIME_APPOINTMENT: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_DOMAIN}/TIME_APPOINTMENT`);
        if (response.ok) {
          json = await response.json();
          json = json.values;
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_TIME_APPOINTMENT', json);
    },

    FETCH_TYPE_APPOINTMENT: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_DOMAIN}/TYPE_APPOINTMENT`);
        if (response.ok) {
          json = await response.json();
          json = json.values;
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_TYPE_APPOINTMENT', json);
    },

    FETCH_TYPE_UPSHOT: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_DOMAIN}/UPSHOT_TYPE`);
        if (response.ok) {
          json = await response.json();
          json = json.values;
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_UPSHOT_TYPE', json);
    },

    /**
     *
     * @param context
     * @param params
     * @returns {Promise<void>}
     * @constructor
     */
    FETCH_TYPE_USER: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_DOMAIN}/TYPE_USER`);
        if (response.ok) {
          json = await response.json();
          json = json.values;
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_TYPE_USER', json);
    },

    FETCH_VOUCHER_TYPE_DISCOUNT: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_DOMAIN}/VOUCHER_TYPE_DISCOUNT`);
        if (response.ok) {
          json = await response.json();
          json = json.values;
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }

      context.commit('POPULATE_VOUCHER_TYPE_DISCOUNT', json);
    },
  },
  getters: {
    APPOINTMENT_SOURCE: (state) => state.appointmentSource,
    APPOINTMENT_STATUS_PAYMENT: (state) => state.appointmentStatusPayment,
    BANK_CODES: (state) => state.bankCodes,
    BILL_TYPE_OPTIONS: (state) => state.billTypeOptions,
    CALLBACK_EVENTS_APPOINTMENT: (state) => state.callbackEventsAppointment,
    COMMISSION_TYPE_OPTIONS: (state) => state.commissionTypeOptions,
    CUSTOM_LANGUAGES: (state) => state.custom_languages,
    CUSTOM_MESSAGES: (state) => state.custom_messages,
    GENDER_OPTIONS: (state) => state.genderOptions,
    GET_ADDRESS: (state) => state.address,
    GET_CITIES: (state) => state.cities,
    GET_COUNTRY: (state) => state.country,
    GET_DEFAULT_USER_PERMISSIONS: (state) => state.defaultUserPermissions,
    GET_FINANCIAL_TEXT_ACCOUNT: (state) => state.textAccount,
    GET_FINANCIAL_TRANSFER_DAY: (state) => state.transferDay,
    GET_FINANCIAL_TRANSFER_INTERVAL: (state) => state.transferInterval,
    GET_FINANCIAL_TYPE_ACCOUNT: (state) => state.typeAccount,
    GET_PAYMENT_INTERMEDIARY: (state) => state.payment_intermediary,
    GET_PAYMENT_STATUS: (state) => state.payment_status,
    GET_PAYMENT_TYPE: (state) => state.payment_type,
    GET_PRESCRIPTION_ADDRESS_OPTIONS: (state) => state.prescription_address_options,
    GET_STATES: (state) => state.states,
    GET_THEME_ROOM_LIGHT: (state) => state.themeRoomLight,
    GET_TYPE_UPSHOT: (state) => state.typeUpshotOptions,
    GET_TYPE_USER: (state) => state.typeUserOptions,
    GET_VOUCHER_TYPE_DISCOUNT: (state) => state.typeDiscount,
    NOTIFICATION_CHANNEL_OPTIONS: (state) => state.notification_channelOptions,
    PARTICIPANT_ROLE: (state) => state.participantRole,
    PROFESSIONAL_REGISTER_TYPES: (state) => state.professional_register_types,
    STATUS_APPOINTMENT: (state) => state.statusAppointment,
    TIME_APPOINTMENT: (state) => state.timeAppointment,
    TYPE_APPOINTMENT: (state) => state.typeAppointment,
  },
  mutations: {
    POPULATE_ADDRESS: (state, payload) => {
      if (payload) {
        state.address = payload;
      }
    },
    POPULATE_APPOINTMENT_SOURCE: (state, payload) => {
      if (payload) {
        state.appointmentSource = payload;
      }
    },
    POPULATE_APPOINTMENT_STATUS_PAYMENT: (state, payload) => {
      if (payload) {
        state.appointmentStatusPayment = payload;
      }
    },
    POPULATE_BANK_CODES: (state, payload) => {
      if (payload) {
        state.bankCodes = payload;
      }
    },
    POPULATE_BILL_TYPE: (state, payload) => {
      if (payload) {
        state.billTypeOptions = payload;
      }
    },
    POPULATE_CALLBACK_EVENTS_APPOINTMENT: (state, payload) => {
      if (payload) {
        state.callbackEventsAppointment = payload;
      }
    },
    POPULATE_CITIES: (state, payload) => {
      if (payload) {
        state.cities = payload;
      }
    },
    POPULATE_COMMISSION_TYPE: (state, payload) => {
      if (payload) {
        state.commissionTypeOptions = payload;
      }
    },
    POPULATE_COUNTRY: (state, payload) => {
      if (payload) {
        state.country = payload;
      }
    },
    POPULATE_CUSTOM_LANGUAGES: (state, payload) => {
      if (payload) {
        state.custom_languages = payload;
      }
    },
    POPULATE_CUSTOM_MESSAGES: (state, payload) => {
      if (payload) {
        state.custom_messages = payload;
      }
    },
    POPULATE_DEFAULT_USER_PERMISSIONS: (state, payload) => {
      if (payload) {
        state.defaultUserPermissions = payload;
      }
    },
    POPULATE_FINANCIAL_TEXT_ACCOUNT: (state, payload) => {
      if (payload) {
        state.textAccount = payload;
      }
    },
    POPULATE_FINANCIAL_TRANSFER_DAY: (state, payload) => {
      if (payload) {
        state.transferDay = payload;
      }
    },
    POPULATE_FINANCIAL_TRANSFER_INTERVAL: (state, payload) => {
      if (payload) {
        state.transferInterval = payload;
      }
    },
    POPULATE_FINANCIAL_TYPE_ACCOUNT: (state, payload) => {
      if (payload) {
        state.typeAccount = payload;
      }
    },
    POPULATE_GENDER: (state, payload) => {
      if (payload) {
        state.genderOptions = payload;
      }
    },
    POPULATE_NOTIFICATION_CHANNEL: (state, payload) => {
      if (payload) {
        state.notification_channelOptions = payload;
      }
    },
    POPULATE_PARTICIPANT_ROLE: (state, payload) => {
      if (payload) {
        state.participantRole = payload;
      }
    },
    POPULATE_PAYMENT_INTERMEDIARY: (state, payload) => {
      if (payload) {
        state.payment_intermediary = payload;
      }
    },
    POPULATE_PAYMENT_STATUS: (state, payload) => {
      if (payload) {
        state.payment_status = payload;
      }
    },
    POPULATE_PAYMENT_TYPE: (state, payload) => {
      if (payload) {
        state.payment_type = payload;
      }
    },
    POPULATE_PRESCRIPTION_NAME_AND_ADDRESS_OPTIONS: (state, payload) => {
      if (payload) {
        state.prescription_address_options = payload;
      }
    },
    POPULATE_PROFESSIONAL_REGISTER_TYPE: (state, payload) => {
      if (payload) {
        state.professional_register_types = payload;
      }
    },
    POPULATE_STATES: (state, payload) => {
      if (payload) {
        state.states = payload;
      }
    },
    POPULATE_STATUS: (state, payload) => {
      if (payload) {
        state.statusOptions = payload;
      }
    },
    POPULATE_STATUS_APPOINTMENT: (state, payload) => {
      if (payload) {
        state.statusAppointment = payload;
      }
    },
    POPULATE_THEME_ROOM_LIGHT: (state, payload) => {
      if (payload) {
        state.themeRoomLight = payload;
      }
    },
    POPULATE_TIME_APPOINTMENT: (state, payload) => {
      if (payload) {
        state.timeAppointment = payload;
      }
    },
    POPULATE_TYPE_APPOINTMENT: (state, payload) => {
      if (payload) {
        state.typeAppointment = payload;
      }
    },
    POPULATE_TYPE_USER: (state, payload) => {
      if (payload) {
        state.typeUserOptions = payload;
      }
    },

    POPULATE_UPSHOT_TYPE: (state, payload) => {
      if (payload) {
        state.typeUpshotOptions = payload;
      }
    },

    POPULATE_VOUCHER_TYPE_DISCOUNT: (state, payload) => {
      if (payload) {
        state.typeDiscount = payload;
      }
    },
  },
  namespaced: true,
  state: {
    address: null,
    appointmentSource: [],
    appointmentStatusPayment: [],
    bankCodes: [],
    billTypeOptions: [],
    callbackEventsAppointment: [],
    cities: [],
    commissionTypeOptions: [],
    country: [],
    custom_languages: [],
    custom_messages: [],
    defaultUserPermissions: [],
    genderOptions: [],
    medicalSpecialtyOptions: [],
    notification_channelOptions: [],
    participantRole: [],
    payment_intermediary: [],
    payment_status: [],
    payment_type: [],
    prescription_address_options: [],
    professional_register_types: [],
    states: [],
    statusAppointment: [],
    statusOptions: [],
    textAccount: null,
    themeRoomLight: [],
    timeAppointment: [],
    transferDay: null,
    transferInterval: null,
    typeAccount: null,
    typeAppointment: [],
    typeDiscount: [],
    typeUpshotOptions: [],
    typeUserOptions: [],
  },
};

CommonState.registerModule('domain', module);
