<template>
  <div>
    <v-autocomplete
      :id="`${this.$route.path.split('/')[1]}__parent_id`"
      v-model="entity"
      :items="domainListPatient"
      item-value="id"
      item-text="name_formatted"
      :disabled="disabled"
      :clearable="clearable"
      :label="_label"
      :title="_title"
      :hint="_hint"
      :filled="filled"
      :return-object="returnObject"
      @input="updateValue"
    />
  </div>
</template>
<script>
export default {
  tag: 'person-select',
  name: 'PersonSelect',
  props: {
    items: Array,
    value: [String, Object],
    returnObject: {
      type: Boolean,
      default: true,
    },
    fetchActive: {
      type: Boolean,
      default: true,
    },
    filled: {
      type: Boolean,
      default: true,
    },
    clearable: Boolean,
    label: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    hint: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    filterByEmail: {
      type: Boolean,
      default: false,
    },
    excludeIds: {
      type: Array,
    },
  },
  data: (vm) => ({
    dialog: false,
    search: '',
    entity: null,
    _label: null,
    _title: null,
    _hint: null,
    vm,
  }),
  computed: {
    domainListPatient: function () {
      let list = this.items || JSON.parse(JSON.stringify(this.$store.getters['patient/GET_ALL']));

      list.map((item) => {
        item.name_formatted = item.name_formatted || item.name;
        return item;
      });

      if (this.excludeIds && this.excludeIds.length) {
        list = list.filter((item) => !this.excludeIds.includes(item.id));
      }

      return this.filterByEmail ? list.filter((item) => item.mail && !item.parent_id) : list;
    },
  },
  watch: {},
  async created() {
    this._label = this.label ? this.label : this.$vuetify.lang.t('$vuetify.label.patient_id');
    this._hint = this.hint ? this.hint : this.$vuetify.lang.t('$vuetify.label.patient_id');
    this._title = this.title ? this.title : this.$vuetify.lang.t('$vuetify.label.patient_id');
    this.entity = this.value;

    if ((!this.domainPatient || this.domainPatient.length < 1) && this.fetchActive) {
      await this.$store.dispatch('patient/FETCH_ALL', {
        fields: 'id,name,cpf,registration,mail,cell_phone,cell_phone_ddi,parent_id',
      });
    }
    if (this.entity) {
      const obj = this.domainListPatient.find((el) => el.id === this.entity);
      this.$emit('setEmail', obj);

      // edit person
      if (obj && obj.cell_phone) {
        delete obj.cell_phone;
      }
      this.$emit('input', obj);
    }
  },
  methods: {
    updateValue: function (val) {
      this.$emit('input', val);
    },
    changeValue: function (val) {
      this.$emit('change', val);
    },
  },
};
</script>
