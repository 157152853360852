import { CommonRouter } from '@primeit/components-vue';

CommonRouter.registerRoutes([
  {
    children: [
      {
        component: () => import(/* webpackChunkName: "Common" */ './List.vue'),
        meta: {
          label: 'consultation.list',
          secure: true,
        },
        path: '',
      },
    ],
    component: () => import(/* webpackChunkName: "Common" */ '../common/Common.vue'),
    meta: {
      icon: 'assignment_turned_in',
      label: 'consultation.list',
      order: 2,
      visible: true,
    },
    path: '/consultation',
  },
]);
