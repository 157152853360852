import '@mdi/font/css/materialdesignicons.css';
import '@fortawesome/fontawesome-free/css/all.css';

import 'vuetify/dist/vuetify.min.css';

import Vue from 'vue';
import Vuetify from 'vuetify';

// i18n
import { enUS, ptBR } from '@/locale';
import { en, pt } from 'vuetify/es5/locale';

Vue.use(Vuetify);

const getCurrentLanguage = () => {
  const lang = navigator.language || navigator.userLanguage;

  let current;

  switch (lang) {
    case 'en':
    case 'en-US':
      current = 'en-US';
      break;

    case 'pt':
    case 'pt-BR':
    default:
      current = 'pt-BR';
      break;
  }

  return current;
};

const opts = {
  lang: {
    current: getCurrentLanguage(),
    locales: {
      'en-US': Object.assign(en, enUS),
      'pt-BR': Object.assign(pt, ptBR),
    },
  },
};

export default new Vuetify(opts);
