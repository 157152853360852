import { CommonRouter } from '@primeit/components-vue';

CommonRouter.registerRoutes([
  {
    children: [
      {
        component: () => import(/* webpackChunkName: "Common" */ './Schedule.vue'),
        meta: {
          label: 'schedule.schedule_availability',
        },
        path: '/schedule_availability',
      },
      {
        component: () => import(/* webpackChunkName: "Common" */ './Holidays.vue'),
        meta: {
          label: 'schedule.holidays',
        },
        path: '/schedule_holidays',
      },
    ],
    component: () => import(/* webpackChunkName: "Common" */ '../common/Common.vue'),
    meta: {
      icon: 'calendar_today',
      label: 'schedule.availability',
      order: 6,
      subroutes: [
        {
          label: 'schedule.doctors',
          path: '/schedule_availability',
          visible: true,
        },
        {
          label: 'schedule.holidays',
          path: '/schedule_holidays',
          visible: true,
        },
      ],
      visible: true,
      visibleRoles: ['C', 'DC', 'M'],
    },
    path: '/schedule',
  },
]);
