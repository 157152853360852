import { CommonState, Fetcher } from '@primeit/components-vue';

// const mock = {
//   settings: {}
// };

const module = {
  actions: {
    FETCH_COMPANY_SETTINGS: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_SETTINGS}/${params.companyId}/settings`
        );
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_COMPANY_SETTINGS', json);
    },
    FETCH_EDIT: async (context, params) => {
      let response;
      let json;
      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_SETTINGS}/${params.companyId}/settings`);
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_EDIT', json);
    },
    FETCH_SAVE: async (context, settings) => {
      let response;
      let json;
      const companyId = settings.companyId;
      delete settings.companyId;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_SETTINGS}/${companyId}/settings`, {
          body: JSON.stringify(settings),
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
          method: 'PUT',
        });
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },
    FETCH_UPDATE: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_SETTINGS}/${params.id}`, {
          body: JSON.stringify(params),
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
          method: 'PUT',
        });
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },
  },
  getters: {
    GET_ALL: (state) => state.entities,
    GET_COMPANY_SETTINGS: (state) => {
      return JSON.parse(JSON.stringify(state.entitySettings));
    },
    GET_EDIT: (state) => {
      return JSON.parse(JSON.stringify(state.edit));
    },
    GET_NEW: (state) => state.new,
    GET_ONE: (state) => {
      return JSON.parse(JSON.stringify(state.entity));
    },
    GET_VALIDATE: (state) => state.validate,
  },
  mutations: {
    POPULATE_ALL: (state, payload) => {
      if (payload) {
        state.entities = payload;
      }
    },
    POPULATE_COMPANY_SETTINGS: (state, payload) => {
      if (payload) {
        state.entitySettings = payload;
      }
    },
    POPULATE_EDIT: (state, payload) => {
      if (payload) {
        state.edit = payload;
      }
    },
    POPULATE_NEW: (state, payload) => {
      if (payload) {
        state.new = payload;
      }
    },
    POPULATE_ONE: (state, payload) => {
      if (payload) {
        state.entity = payload;
      }
    },
    POPULATE_VALIDATE: (state, payload) => {
      if (payload) {
        state.entities = payload;
      }
    },
  },
  namespaced: true,
  state: {
    edit: null,
    entities: [],
    entity: null,
    entitySettings: [],
    new: [],
    validate: null,
  },
};

CommonState.registerModule('settings', module);
