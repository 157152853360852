import { CommonRouter } from '@primeit/components-vue';

CommonRouter.registerRoutes([
  {
    component: () => import(/* webpackChunkName: "Common" */ './Login.vue'),
    meta: {
      icon: 'login',
      label: 'appointment',
      order: 0,
      visible: false,
    },
    path: '/login',
  },
]);
