import { CommonRouter } from '@primeit/components-vue';

CommonRouter.registerRoutes([
  {
    children: [
      {
        component: () => import(/* webpackChunkName: "Common" */ './Home.vue'),
        meta: {
          label: 'home',
          secure: true,
        },
        path: '',
      },
    ],
    component: () => import(/* webpackChunkName: "Common" */ '../common/Common.vue'),
    meta: {
      icon: 'home',
      label: 'home',
      order: 0,
      visible: false,
    },
    path: '/',
  },
]);
