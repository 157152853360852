import { CommonState, Fetcher } from '@primeit/components-vue';
import { Config } from '@dav/security-component-spa';

const module = {
  actions: {
    FETCH_ALL: async (context, params) => {
      let response;
      let json;
      const fields = 'id,name,mail,company_id,user_type,status';
      let counter = 0;
      let filter = '';
      if (!params) {
        params = {
          status: true,
        };
      }

      Object.entries(params).find(([key, value], index) => {
        if (value !== '' && value !== null) {
          if (counter > 0) filter += ',';
          filter += `${key}=${value}`;
          counter++;
        }
      });

      try {
        response = await Fetcher(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_USER}?filter=${filter}&fields=${fields}`
        );
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_ALL', json);
    },
    FETCH_ALTER_STATUS: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_USER}?id=${params.id}`, {
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
          method: 'PUT',
        });
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },
    FETCH_DELETE: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_USER}/${params}`, {
          method: 'DELETE',
        });
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },
    FETCH_DELETE_ALL: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_USER}?id=${params}`, {
          method: 'DELETE',
        });
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },
    FETCH_EDIT: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_USER}/${params}`);
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_EDIT', json);
    },
    FETCH_NEW: async (context, params) => {
      let response;
      let json;
      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_USER}/new`);
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_NEW', json);
    },
    FETCH_ONE: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_USER}/${params}`);
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_ONE', json);
    },
    FETCH_SAVE: async (context, params) => {
      let response;
      let json;

      const config = await Config.getInstance();

      const behalfOf = config.get('dav-saas-behalf-of');

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_USER}`, {
          body: JSON.stringify(params),
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'x-dav-saas-behalf-of': behalfOf,
          },
          method: 'POST',
        });
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },
    FETCH_SETUP: async (context, params) => {
      let response;
      let json;
      try {
        response = await Fetcher(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_USER}/${params.id}/setup?fields=${params.fields}`
        );
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_SETUP', json);
    },
    FETCH_TERM_REPORT: async (context, params) => {
      let response;
      let json = [];

      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };

      const filter = [];
      if (params && params.filter) {
        const filterKeys = Object.keys(params.filter);
        if (filterKeys) {
          for (const filterKey of filterKeys) {
            const filterValue = params.filter[filterKey];
            filter.push(`${filterKey}=${filterValue}`);
          }
        }
      }
      const filterStr = filter.join(',');
      const filterParam = filter.length > 0 ? `?filter=${filterStr}` : '';
      try {
        response = await Fetcher(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_USER}/term/report${filterParam}`,
          headers
        );
        json = await response.json();
      } catch (e) {
        //
      }
      context.commit('POPULATE_TERM_REPORT', json);
    },
    FETCH_TERM_USER: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_USER}/term`);
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_TERM_USER', json);
    },

    FETCH_TERM_USER_ACCEPT: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_USER}/term`, {
          body: JSON.stringify(params),
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
          method: 'POST',
        });
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },

    FETCH_TIMEZONE: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_USER}/${params}/timezone`);
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_TIMEZONE', json);
    },
    FETCH_UPDATE: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_USER}/${params.id}`, {
          body: JSON.stringify(params),
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
          method: 'PUT',
        });
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },

    FETCH_USERS: async (context, params) => {
      let response;
      let json;
      const fields = 'id,name,mail,company_id,user_type,status';
      let counter = 0;
      let filter = '';
      if (!params) {
        params = {
          status: true,
        };
      }

      Object.entries(params).find(([key, value], index) => {
        if (value !== '' && value !== null) {
          if (counter > 0) filter += ',';
          filter += `${key}=${value}`;
          counter++;
        }
      });

      try {
        response = await Fetcher(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_USER}?filter=${filter}&fields=${fields}`
        );
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_USERS', json);
    },

    FETCH_VALIDATE: async (context, params) => {
      let response;
      let json;
      try {
        response = await Fetcher(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_USER}/validate?field=${params.field}&condition=${params.condition}&value=${params.value}`
        );
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_VALIDATE', json);
    },
  },
  getters: {
    GET_ALL: (state) => state.entities,
    GET_EDIT: (state) => {
      return JSON.parse(JSON.stringify(state.edit));
    },
    GET_NEW: (state) => state.new,
    GET_ONE: (state) => {
      return JSON.parse(JSON.stringify(state.entity));
    },
    GET_SETUP: (state) => state.setup,
    GET_TERM_REPORT: (state) => state.term_report,
    GET_TIMEZONE: (state) => state.timezone,
    GET_USERS: (state) => state.users_entities,
    GET_VALIDATE: (state) => state.validate,
    TERM_USER: (state) => state.term_user,
  },
  mutations: {
    POPULATE_ALL: (state, payload) => {
      if (payload) {
        state.entities = payload;
      }
    },
    POPULATE_EDIT: (state, payload) => {
      if (payload) {
        state.edit = payload;
      }
    },
    POPULATE_NEW: (state, payload) => {
      if (payload) {
        state.new = payload;
      }
    },
    POPULATE_ONE: (state, payload) => {
      if (payload) {
        state.entity = payload;
      }
    },
    POPULATE_SETUP: (state, payload) => {
      if (payload) {
        state.setup = payload;
      }
    },
    POPULATE_TERM_REPORT: (state, payload) => {
      if (payload) {
        state.term_report = payload;
      }
    },
    POPULATE_TERM_USER: (state, payload) => {
      if (payload) {
        state.term_user = payload;
      }
    },

    POPULATE_TIMEZONE: (state, payload) => {
      if (payload) {
        state.timezone = payload;
      }
    },

    POPULATE_USERS: (state, payload) => {
      if (payload) {
        state.users_entities = payload;
      }
    },

    POPULATE_VALIDATE: (state, payload) => {
      if (payload) {
        state.entities = payload;
      }
    },
  },
  namespaced: true,
  state: {
    edit: null,
    entities: [],
    entity: null,
    new: [],
    setup: null,
    term_report: null,
    term_user: [],
    timezone: null,
    users_entities: [],
    validate: null,
  },
};

CommonState.registerModule('user', module);
