import { CommonState, Fetcher, FetchHelper } from '@primeit/components-vue';
const fetchHelper = new FetchHelper();
const module = {
  actions: {
    FETCH_ALL: async (context, params) => {
      if (!params) {
        params = {};
      }
      params.fields = params.fields ? params.fields : 'id,company_id';
      const response = await fetchHelper.fetchAll(
        `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_MESSAGE}/`,
        params,
        true
      );
      context.commit('POPULATE_ALL', response);
    },
    FETCH_COUNT_UNREAD: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_MESSAGE}/unread`);
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          unread: 0,
        };
      }

      context.commit('POPULATE_COUNT_UNREAD', json ? json.unread : 0);
    },
    FETCH_NEW: async (context, params) => {
      let json = {
        code: 400,
        message: 'Impossível acessar o servidor. Tente novamente',
      };
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_MESSAGE}`, {
          body: JSON.stringify(params),
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
          method: 'POST',
        });
        if (response.ok) {
          json = {
            code: 200,
            message: 'new_message_success',
          };
        }
      } catch (e) {
        //
      }
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },
    FETCH_ONE: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_MESSAGE}/${params}`);
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }

      context.commit('POPULATE_ONE', json);
    },
    FETCH_RESPONSE: async (context, params) => {
      let json = {
        code: 400,
        message: 'Impossível acessar o servidor. Tente novamente',
      };
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_MESSAGE}/${params.id}/response`, {
          body: JSON.stringify({
            body: params.body,
          }),
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
          method: 'PUT',
        });
        if (response.ok) {
          const responseBody = await response.json();
          json = {
            code: 200,
            data: responseBody.data,
            message: 'response_success',
          };
        }
      } catch (e) {
        //
      }
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },
    FETCH_SEEN: async (context, params) => {
      try {
        await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_MESSAGE}/${params}/seen`, {
          method: 'PUT',
        });
      } catch (e) {
        //
      }
    },
    FETCH_ARCHIVE_MESSAGE: async (context, params) => {
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_MESSAGE}/archive`, {
          body: JSON.stringify(params),
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
          method: 'PUT',
        });
        return response.json();
      } catch (e) {
        //
      }
    },
    FETCH_UNARCHIVE_MESSAGE: async (context, params) => {
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_MESSAGE}/unarchive`, {
          body: JSON.stringify(params),
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
          method: 'PUT',
        });
        return response.json();
      } catch (e) {
        //
      }
    }
  },
  getters: {
    GET_ALL: (state) => JSON.parse(JSON.stringify(state.messages)),
    GET_COUNT_UNREAD: (state) => JSON.parse(JSON.stringify(state.count_unread)),
    GET_ONE: (state) => JSON.parse(JSON.stringify(state.message)),
  },
  mutations: {
    POPULATE_ALL: (state, payload) => {
      if (payload) {
        state.messages = payload;
      }
    },
    POPULATE_COUNT_UNREAD: (state, payload) => {
      if (payload) {
        state.count_unread = payload;
      }
    },
    POPULATE_ONE: (state, payload) => {
      if (payload) {
        state.message = payload;
      }
    },
  },
  namespaced: true,
  state: {
    count_unread: 0,
    message: null,
    messages: [],
  },
};

CommonState.registerModule('message', module);
