import { API, CommonState, Fetcher, FetchHelper } from '@primeit/components-vue';
import { Config } from '@dav/security-component-spa';

const fetchHelper = new FetchHelper();
const module = {
  actions: {
    FETCH_ALL: async (context, params) => {
      if (!params) {
        params = {};
      }
      params.fields = params.fields ? params.fields : 'id,company_id';
      const response = await fetchHelper.fetchAll(
        `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_APPOINTMENT}/`,
        params,
        true
      );
      context.commit('POPULATE_ALL', response);
    },
    FETCH_CONFIRM_PAYMENT: async (context, params) => {
      const apiName = 'FinancialAPI';
      const path = '/confirmpayment';

      const config = await Config.getInstance();

      const myInit = {
        body: {},
        headers: {
          'x-company-id': config.get('dav-company-id'),
        },
        queryStringParameters: {
          access: params.id,
          protocol: params.protocol,
          refuse: params.refuse,
        },
        response: true,
      };

      const json = await API.post(apiName, path, myInit)
        .then((payload) => {
          if (payload.status === 200) {
            return payload.data;
          } else {
            return false;
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            context.commit('common/POPULATE_FEEDBACK', error.response.data, {
              root: true,
            });
            return error.response.data;
          } else {
            return {
              code: 400,
              message: 'Impossível acessar o servidor. Tente novamente',
            };
          }
        });

      context.commit('POPULATE_CONFIRM_PAYMENT', json);
    },
    FETCH_DELETE: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_APPOINTMENT}/${params}`, {
          method: 'DELETE',
        });
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }

      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },
    FETCH_DELETE_ALL: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_APPOINTMENT}?id=${params}`, {
          method: 'DELETE',
        });
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }

      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },
    FETCH_DELETE_FILE: async (context, params) => {
      let response;
      let json;
      const filePath = params.filePath;
      try {
        response = await Fetcher(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_APPOINTMENT}/${params.id}/file?filePath=${filePath}`,
          {
            headers: {
              Accept: 'application/json, text/plain, */*',
              'Content-Type': 'application/json',
            },
            method: 'DELETE',
          }
        );
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }

      context.commit('POPULATE_FILE', json);
    },
    FETCH_DETAIL: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_APPOINTMENT}/${params}/detail`);
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }

      context.commit('POPULATE_ONE', json);
    },

    FETCH_EDIT: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_APPOINTMENT}/${params}`);
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }

      context.commit('POPULATE_EDIT', json);
    },

    FETCH_LIST: async (context, params) => {
      const response = await fetchHelper.fetchAll(
        `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_APPOINTMENT}/list`,
        params,
        true
      );
      context.commit('POPULATE_LIST', response);
    },

    FETCH_NEW: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_APPOINTMENT}/new`);
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_NEW', json);
    },
    FETCH_NOTIFY: async (context, params) => {
      let response;
      let json;

      const body = {
        notification_channels: params.notification_channels,
        participant_id: params.participant_id,
      };

      try {
        response = await Fetcher(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_APPOINTMENT}/${params.entity_id}/notify`,
          {
            body: JSON.stringify(body),
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            method: 'PUT',
          }
        );
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }

      context.commit('POPULATE_NOTIFY', json);
    },
    FETCH_PDF: async (context, params) => {
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_APPOINTMENT}/${params}/pdf`);
        if (response.ok) {
          const json = await response.json();
          return json;
        }
      } catch (e) {
        return {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
    },
    FETCH_REPORT: async (context, params) => {
      const response = await fetchHelper.fetchAll(
        `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_APPOINTMENT}/report`,
        params,
        true
      );
      context.commit('POPULATE_REPORT', response);
    },

    FETCH_REPORTFINANCE: async (context, params) => {
      const response = await fetchHelper.fetchAll(
        `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_APPOINTMENT}/reportfinance`,
        params,
        true
      );
      context.commit('POPULATE_REPORTFINANCE', response);
    },

    FETCH_REPORTNPS: async (context, params) => {
      const response = await fetchHelper.fetchAll(
        `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_APPOINTMENT}/npsreport`,
        params,
        true
      );
      context.commit('POPULATE_REPORTNPS', response);
    },

    FETCH_SAVE: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_APPOINTMENT}`, {
          body: JSON.stringify(params),
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
          method: 'POST',
        });
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }

      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },

    FETCH_SAVE_PRESCRIPTION_DATA: async (context, params) => {
      const apiName = 'RoomAPI';

      const myInit = {
        body: params.body,
        headers: {
          'x-auth-room-participant': sessionStorage.getItem('x-auth-room-participant'),
        },
        queryStringParameters: {
          id: params.id,
        },
        response: true,
      };

      const json = await API.put(apiName, params.path, myInit)
        .then((payload) => {
          if (payload.status === 200) {
            return payload.data;
          } else {
            return false;
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            context.commit('common/POPULATE_FEEDBACK', error.response.data, {
              root: true,
            });
            return error.response.data;
          } else {
            return {
              code: 400,
              message: 'Impossível acessar o servidor. Tente novamente',
            };
          }
        });
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },

    FETCH_SEND_FILE: async (context, params) => {
      let response;
      let json;
      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_APPOINTMENT}/${params.id}/file`, {
          body: JSON.stringify(params.file),
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
          method: 'PUT',
        });
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }

      context.commit('POPULATE_FILE', json);
    },
    FETCH_TIMELINE: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_APPOINTMENT}/${params}/timeline`);
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }

      context.commit('POPULATE_TIMELINE', json);
    },
    FETCH_UPDATE: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_APPOINTMENT}/${params.id}`, {
          body: JSON.stringify(params),
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
          method: 'PUT',
        });
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }

      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },
    SAVE_APPOINTMENT_IN_LIST: async (context, params) => {
      context.commit('SAVE_ITEM_IN_LIST', params);
    },
  },
  getters: {
    GET_ALL: (state) => state.entities,
    GET_CONFIRM_PAYMENT: (state) => {
      return JSON.parse(JSON.stringify(state.confirm_payment));
    },
    GET_EDIT: (state) => {
      return JSON.parse(JSON.stringify(state.edit));
    },
    GET_FILE: (state) => state.file,
    GET_LIST: (state) => state.list,
    GET_NEW: (state) => state.new,
    GET_NOTIFY: (state) => state.notify,
    GET_ONE: (state) => {
      return JSON.parse(JSON.stringify(state.entity));
    },
    GET_REPORT: (state) => state.report,
    GET_REPORTFINANCE: (state) => state.reportFinance,
    GET_REPORTNPS: (state) => state.reportNps,
    GET_TIMELINE: (state) => JSON.parse(JSON.stringify(state.timeline)),
    GET_VALIDATE: (state) => state.validate,
  },
  mutations: {
    POPULATE_ALL: (state, payload) => {
      if (payload) {
        state.entities = payload;
      }
    },
    POPULATE_CONFIRM_PAYMENT: (state, payload) => {
      if (payload) {
        state.confirm_payment = payload;
      }
    },
    POPULATE_EDIT: (state, payload) => {
      if (payload) {
        state.edit = payload;
      }
    },
    POPULATE_FILE: (state, payload) => {
      if (payload) {
        state.file = payload;
      }
    },
    POPULATE_LIST: (state, payload) => {
      if (payload) {
        state.list = payload;
      }
    },
    POPULATE_NEW: (state, payload) => {
      if (payload) {
        state.new = payload;
      }
    },
    POPULATE_NOTIFY: (state, payload) => {
      if (payload) {
        state.notify = payload;
      }
    },
    POPULATE_ONE: (state, payload) => {
      if (payload) {
        state.entity = payload;
      }
    },
    POPULATE_REPORT: (state, payload) => {
      if (payload) {
        state.report = payload;
      }
    },
    POPULATE_REPORTFINANCE: (state, payload) => {
      if (payload) {
        state.reportFinance = payload;
      }
    },
    POPULATE_REPORTNPS: (state, payload) => {
      if (payload) {
        state.reportNps = payload;
      }
    },
    POPULATE_TIMELINE: (state, payload) => {
      if (payload) {
        state.timeline = payload;
      }
    },
    POPULATE_VALIDATE: (state, payload) => {
      if (payload) {
        state.validate = payload;
      }
    },
    SAVE_ITEM_IN_LIST: (state, payload) => {
      if (payload) {
        state.list.find((opt, index) => {
          if (opt && opt.id === payload.id) {
            state.list.splice(index, 1);
          }
        });
        state.list.push(payload);
      }
    },
  },
  namespaced: true,
  state: {
    confirm_payment: null,
    edit: null,
    entities: [],
    entity: null,
    file: null,
    list: null,
    new: [],
    notify: null,
    report: null,
    reportFinance: null,
    reportNps: null,
    timeline: null,
    validate: null,
  },
};

CommonState.registerModule('appointment', module);
