import { CommonState, Fetcher } from '@primeit/components-vue';

const module = {
  actions: {
    FETCH_CONTRACT_ALLOW_PARTICIPANT_ADD: async (context, params) => {
      let json;
      try {
        const url = `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_CONTRACT}/enforce/${params.companyId}/APPOINTMENT_ADD_PARTICIPANT`;
        const response = await Fetcher(url);
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }

      context.commit('POPULATE_ALLOW_PARTICIPANT_ADD', json);
    },
    FETCH_CONTRACT_ALLOW_PROFESSIONAL_NEW: async (context, params) => {
      let json;
      try {
        const url = `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_CONTRACT}/enforce/${params.companyId}/PROFESSIONAL_NEW`;
        const response = await Fetcher(url);
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }

      context.commit('POPULATE_ALLOW_PROFESSIONAL_NEW', json);
    },
    FETCH_CONTRACT_ALLOW_RECORD_ATTENDANCE: async (context, params) => {
      let json;
      try {
        const url = `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_CONTRACT}/enforce/${params.companyId}/APPOINTMENT_CAN_RECORD`;
        const response = await Fetcher(url);
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }

      context.commit('POPULATE_ALLOW_RECORD_ATTENDANCE', json);
    },
    FETCH_CONTRACT_DISPLAY_POWERED_BY: async (context, params) => {
      let json;
      try {
        const url = `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_CONTRACT}/enforce/${params.companyId}/DISPLAY_POWERED_BY`;
        const response = await Fetcher(url);
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }

      context.commit('POPULATE_DISPLAY_POWERED_BY', json);
    },
    FETCH_CONTRACT_ENABLE_WEBSITE: async (context, params) => {
      let json;
      try {
        const url = `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_CONTRACT}/enforce/${params.companyId}/ENABLE_WEBSITE`;
        const response = await Fetcher(url);
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }

      context.commit('POPULATE_ENABLE_WEBSITE', json);
    },
    FETCH_CONTRACT_USE_EMERGENCY_MODULE: async (context, params) => {
      let json;
      try {
        const url = `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_CONTRACT}/enforce/${params.companyId}/USE_EMERGENCY_MODULE`;
        const response = await Fetcher(url);
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }

      context.commit('POPULATE_USE_EMERGENCY_MODULE', json);
    },
  },
  getters: {
    ALLOW_PARTICIPANT_ADD: (state) => state.allowParticipantAdd,
    ALLOW_PROFESSIONAL_NEW: (state) => state.allowProfessionalNew,
    ALLOW_RECORD_ATTENDANCE: (state) => state.allowRecording,
    DISPLAY_POWERED_BY: (state) => state.displayPoweredBy,
    ENABLE_WEBSITE: (state) => state.enableWebsite,
    USE_EMERGENCY_MODULE: (state) => state.useEmergencyModule,
  },
  mutations: {
    POPULATE_ALLOW_PARTICIPANT_ADD: (state, payload) => {
      if (payload) {
        state.allowParticipantAdd = payload;
      }
    },
    POPULATE_ALLOW_PROFESSIONAL_NEW: (state, payload) => {
      if (payload) {
        state.allowProfessionalNew = payload;
      }
    },
    POPULATE_ALLOW_RECORD_ATTENDANCE: (state, payload) => {
      if (payload) {
        state.allowRecording = payload;
      }
    },
    POPULATE_DISPLAY_POWERED_BY: (state, payload) => {
      if (payload) {
        state.displayPoweredBy = payload;
      }
    },
    POPULATE_ENABLE_WEBSITE: (state, payload) => {
      if (payload) {
        state.enableWebsite = payload;
      }
    },
    POPULATE_USE_EMERGENCY_MODULE: (state, payload) => {
      if (payload) {
        state.useEmergencyModule = payload;
      }
    },
  },
  namespaced: true,
  state: {
    allowParticipantAdd: null,
    allowProfessionalNew: null,
    allowRecording: null,
    displayPoweredBy: null,
    enableWebsite: null,
    useEmergencyModule: null,
  },
};

CommonState.registerModule('contract', module);
