import { CommonRouter } from '@primeit/components-vue';

CommonRouter.registerRoutes([
  {
    children: [
      {
        component: () => import(/* webpackChunkName: "pageNotFound" */ './pageNotFound.vue'),
        meta: {
          label: 'pageNotFound',
          secure: false,
          visible: false,
        },
        path: '',
      }
    ],
    component: () => import(/* webpackChunkName: "Common" */ '../common/Common.vue'),
    meta: {
      label: 'pageNotFound',
      secure: true,
      visible: false,
    },
    path: '/page-not-found',
    name: 'page-not-found',
  },
  {
    component: () => import(/* webpackChunkName: "pageNotFound" */ './pageNotFound.vue'),
    meta: {
      secure: true,
      visible: false,
    },
    path: '/pagenotfound',
  },
  {
    children: [
      {
        component: () => import(/* webpackChunkName: "Common" */ './pageNotFound.vue'),
        meta: {
          label: 'pageNotFound',
          secure: true,
          visible: false,
        },
        path: '*',
      },
    ],
    component: () => import(/* webpackChunkName: "Common" */ '../common/Common.vue'),
    meta: {
      icon: 'dns',
      label: 'domains.Edit',
      order: 600,
      visible: false,
    },
    path: '*',
  },
]);
